import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useTolgee, useTranslate } from "@tolgee/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useAppState from "../store/appState";
import { server } from "../AppSetting";
import fallbackImage from "../assets/images/resultsPlaceholder.png";
import { T } from "@tolgee/react";
import axios from "../axios";
import { DownloadIcon, CopyIcon, QRIcon, ViewIcon, ShareIcon } from '../assets/icons';

function ResultsCard({ material }) {
  let host = server;
  if (host == "") host = window.location.origin;

  const { isMobile, scrollbarWidth } = useAppState();
  const { t } = useTranslate();
  const [showCopied, setShowCopied] = useState(false);

  const encodedMaterialURL = encodeURIComponent(material.downloadURL);
  const materialDownloadURL = material.isDirectUrl ? material.downloadURL : `${host}/api/materials/get?filePath=${encodedMaterialURL}`;
  const materialImageURL = material.useImageStore ? `${host}/api/materials/preview?filePath=${material.imageURL}` : material.imageURL;

  const [showQRModal, setShowQRModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const { supportedLanguages } = useAppState();
  const tolgee = useTolgee(["language"]);

  function getLanguageKey() {
    var languageCode = tolgee.getLanguage();
    return supportedLanguages.find((l) => l.languageCode == languageCode)?.languageNumber ?? 0;
  }

  const handleOpenQRModal = () => {
    document.body.classList.add("no-scrollbar-shift");
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    toggleQRCode(true);
  };

  const handleOpenPreviewModal = () => {
    document.body.classList.add("no-scrollbar-shift");
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    setShowPreviewModal(true);
    updateBackend("preview", materialDownloadURL);
  };

  useEffect(() => {
    if (showQRModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
      document.body.style.paddingRight = 0;
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showQRModal]);

  useEffect(() => {
    if (showPreviewModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
      document.body.style.paddingRight = 0;
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showPreviewModal]);

  const toggleQRCode = (state) => {
    setShowQRModal(state);
    if (state) {
      updateBackend("qr", materialDownloadURL);
    }
  };

  const userCopied = () => {
    setShowCopied(true);
    updateBackend("copyLink", materialDownloadURL);
  };

  function formatBytes(bytes, decimals) {
    if (bytes == 0) return "";
    var k = 1024,
      dm = decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  function updateBackend(downloadType, fileName) {
    try {
      // Send to backend
      axios.post(`/materials/download/${downloadType}/${getLanguageKey()}`, {
        assetName: fileName,
        category: material.category,
        use: material.use,
        typeOfDocument: material.typeOfDocument,
        series: material.series,
      });
    } catch (error) { }
  }


  function shareContent(materialDownloadURL) {
    if (navigator.share) {
      navigator.share({
        title: material.productName,
        text: `${t("ResultsCard.share-text")}`,

        url: materialDownloadURL
      })
        .then(() => updateBackend("shareLink", materialDownloadURL))
        /*.then(() => console.log(`Share complete for ${materialDownloadURL}`))*/
        .catch((error) => console.error('Could not share at this time', error));
    } else {
      console.error('Web Share API is not supported in your browser.');
    }
  }




  if (isMobile) {
    return (
      <>
        <li className="font-roboto w-[342px] h-[150px] flex flex-row select-none ">
          {/* <div
            className={`w-[100px]  h-full items-center flex border-solid border border-grey-2 ${!materialImageURL.includes("extensions") ? "cursor-pointer" : ""}`}
            onClick={!materialImageURL.includes("extensions") ? () => handleOpenPreviewModal() : undefined}
          >

            <ImageWithFallback
              className={` w-full h-[146px] bg-green-400`}
              src={materialImageURL}
              alt={materialImageURL}
              fallbackSrc={fallbackImage}
            />
          </div> */}


          <div
            // cursor becomes pointer & onClick added ONLY if preview is available
            className={` overflow-hidden w-[108px] h-full items-center justify-center flex border-solid border border-grey-2 ${!materialImageURL.includes("extensions") ? "cursor-pointer" : ""}`}
            onClick={!materialImageURL.includes("extensions") ? () => handleOpenPreviewModal() : undefined}

          >
            <ImageWithFallback
              src={materialImageURL}
              fallbackSrc={fallbackImage}
              alt={material.imageURL}
              className={`w-full h-auto `}
            />
          </div>




          <div className="flex flex-col w-[242px] justify-center pl-5  ">
            <div className="flex flex-col mb-3 ">
              <p className=" text-[18px] leading-6 font-semibold line-clamp-2 mb-1 ">{material.productName}</p>
              {material.sizeMB > 0 && (
                <p className="leading-4 text-grey-5 text-[14px] font-inter">{formatBytes(material.sizeMB)}</p>
              )}
            </div>

            <div className="flex flex-row flex-wrap gap-x-5 underline text-dark-grey gap-1">

              {/* render different icon and label dependant on if it's a directURL  */}
              {material.isDirectUrl ? (
                /* view */
                <a
                  className="flex flex-row gap-0.5 py-[8px] pr-[7px] pl-[1px] rounded-md items-center   hover:bg-grey-3-sp active:bg-grey-2"
                  href={materialDownloadURL}
                  onClick={() => updateBackend("download", materialDownloadURL)} // treating a 'view' the same as a 'download' with regard to metrics
                  target="_blank"
                >
                  <img src={ViewIcon} className="w-[18px] h-[18px]" alt="" />
                  <div className="font-semibold leading-[18px] text-sm"> {t("ResultsCard.view")}</div>
                </a>
              ) : (
                /* download */
                <a
                  className="flex flex-row gap-0.5 py-[8px] pr-[7px] pl-[1px] rounded-md items-center   hover:bg-grey-3-sp active:bg-grey-2"
                  href={materialDownloadURL}
                  onClick={() => updateBackend("download", materialDownloadURL)}
                  download
                >
                  <img src={DownloadIcon} className="w-[18px] h-[18px]" alt="" />
                  <div className="font-semibold leading-[18px] text-sm"> {t("ResultsCard.download")}</div>
                </a>
              )}

              {/* copy, now replaced with share */}
              {/*<CopyToClipboard text={materialDownloadURL}>*/}
              {/*  <button className="flex flex-row gap-0.5 py-[4px] pr-[4px] pl-[1px] rounded-md items-center cursor-pointer  hover:bg-grey-3-sp active:bg-grey-2   " onClick={userCopied}>*/}
              {/*    <img src={CopyIcon} className="w-[18px] h-[18px]" alt="" />*/}
              {/*    <div className="font-semibold text-sm">*/}
              {/*      {t("ResultsCard.copy-link")}*/}
              {/*    </div>*/}

              {/*  </button>*/}
              {/*</CopyToClipboard>*/}




              {/* share */}
              <button
                className="flex flex-row gap-0.5 py-[8px] pr-[7px] pl-[4px] rounded-md items-center hover:bg-grey-3-sp active:bg-grey-2"
                onClick={() => shareContent(materialDownloadURL, material.productName)}
              >

                <img src={ShareIcon} className="w-[18px] h-[18px]" alt="" />
                <div className="font-semibold text-sm"> {t("ResultsCard.share")}</div>
              </button>


              {/* show qr modal */}
              <button
                className="flex flex-row gap-0.5 py-[8px] pr-[7px] pl-[1px] rounded-md items-center hover:bg-grey-3-sp active:bg-grey-2"
                onClick={() => handleOpenQRModal()}
              >
                <img src={QRIcon} className="w-[18px] h-[18px]" alt="" />
                <div className="font-semibold text-sm">{material.isDirectUrl ? (t("ResultsCard.view-on-mobile")) : (t("ResultsCard.download-on-mobile"))}</div>
              </button>

            </div>
          </div>
        </li>

        {/* mobile qr modal */}
        {
          showQRModal &&
          <div className="z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center p-6" onClick={() => toggleQRCode(false)}>
            <div className="bg-white rounded-lg p-6" onClick={(e) => e.stopPropagation()}>
              <div className="flex justify-end">
                <svg className="cursor-pointer" onClick={() => toggleQRCode(false)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="flex flex-col justify-center p-8 gap-x-8 ">
                <div className=" flex flex-col align-middle  items-center w-full max-w-72 ">
                  <p className="text-center pb-6 font-inter font-semibold">{material.isDirectUrl ? (<T keyName="qr-modal-text-view" />) : (<T keyName="qr-modal-text-download" />)}</p>
                  <QRCode value={materialDownloadURL} size={128} style={{ height: "100%", width: "100%" }} viewBox={`0 0 256 256`} bgColor={"white"} />
                </div>
              </div>
            </div>
          </div>
        }


        {/* mobile preview modal */}
        {
          showPreviewModal && (
            <div className="z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center p-4" onClick={() => setShowPreviewModal(false)}>
              <div className="bg-white rounded-lg w-full h-full p-6" onClick={(e) => e.stopPropagation()}>
                <div className="flex justify-end">
                  <svg
                    className="cursor-pointer"
                    onClick={() => setShowPreviewModal(false)}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="flex justify-center p-4 h-full">
                  <ImageWithFallback
                    src={materialImageURL}
                    // src="https://wallpapers.com/images/high/homer-simpson-portrait-650c9n5a4fpapnzc.png"
                    fallbackSrc={fallbackImage}
                    alt={material.imageURL}
                    className=" object-contain"
                  />
                </div>
              </div>
            </div>
          )
        }


      </>

    );
  } else {
    return (
      <>
        <li className="flex flex-col font-roboto w-[185px] h-[372px] ">
          <div
            // cursor becomes pointer & onClick added ONLY if preview is available
            className={` w-full  h-[264px] items-center justify-center flex border-solid border border-grey-2 ${!materialImageURL.includes("extensions") ? "cursor-pointer" : ""}`}
            onClick={!materialImageURL.includes("extensions") ? () => handleOpenPreviewModal() : undefined}
          >
            <ImageWithFallback
              src={materialImageURL}
              //src="https://wallpapers.com/images/high/homer-simpson-portrait-650c9n5a4fpapnzc.png"
              fallbackSrc={fallbackImage}
              alt={material.imageURL}
              className={`w-full h-auto`}

            />
          </div>




          <div className=" mt-[10px] mb-[9px]">
            <p className=" text-[16px] leading-5 font-semibold line-clamp-2 mb-1">
              {material.productName}
              {/* Some File.pdf */}
            </p>

            {material.sizeMB > 0 && (
              <p className=" text-[14px] leading-4 text-grey-5 font-inter font-normal">{formatBytes(material.sizeMB)}</p>
            )}

          </div>

          <div className="flex flex-row gap-5 mt-auto	">

            {/* render different icon and label dependant on if it's a directURL  */}
            {material.isDirectUrl ? (

              /* visualise */
              <div className="tooltip hover:bg-grey-3-sp active:bg-grey-2 rounded-md transition-all duration-300	">
                <a
                  className="flex flex-row items-center text-grey-2-sp"
                  href={materialDownloadURL}
                  onClick={() => updateBackend("download", materialDownloadURL)}
                  target="_blank"
                >
                  <img src={ViewIcon} className="p-[2px]" alt="" />
                </a>
                <span class="tooltiptext">{t("ResultsCard.view")}</span>
              </div>
            )

              :

              /* download */

              (
                <div className="tooltip hover:bg-grey-3-sp active:bg-grey-2 rounded-md transition-all duration-300	">
                  <a
                    className="flex flex-row  items-center   text-grey-2-sp   "
                    href={materialDownloadURL}
                    onClick={() => updateBackend("download", materialDownloadURL)}
                    download
                  >
                    <img src={DownloadIcon} className="p-[2px]" alt="" />
                  </a>
                  <span class="tooltiptext">{t("ResultsCard.download")}</span>
                </div>
              )
            }




            {/* copy to clipboard */}
            <button class="tooltip cursor-pointer hover:bg-grey-3-sp active:bg-grey-2 rounded-md transition-all duration-300"
              onMouseLeave={() => setShowCopied(false)} >
              <CopyToClipboard text={materialDownloadURL}>
                <div className="flex flex-row items-center  	   text-grey-2-sp  " onClick={userCopied}>
                  <img src={CopyIcon} className="p-[2px]" alt="" />
                </div>
              </CopyToClipboard>
              <span class="tooltiptext">{showCopied ? (t("ResultsCard.link-copied")) : (t("ResultsCard.copy-link"))}</span>
            </button>

            {/* show qr code modal */}
            <button
              class="tooltip hover:bg-grey-3-sp active:bg-grey-2 rounded-md transition-all duration-300 "
              onClick={() => handleOpenQRModal()}
            >
              <img src={QRIcon} className="p-[2px]" alt="" />
              <span class="tooltiptext">{material.isDirectUrl ? ((t("ResultsCard.view-on-mobile"))) : ((t("ResultsCard.download-on-mobile")))}</span>
            </button>

          </div>
        </li>


        {/* desktop qr modal */}
        {showQRModal &&
          <div className="z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center" onClick={() => toggleQRCode(false)}>
            <div className="bg-white rounded-lg p-4" onClick={(e) => e.stopPropagation()}>
              <div className="flex justify-end">
                <svg className="cursor-pointer" onClick={() => toggleQRCode(false)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="flex flex-row justify-center p-8 gap-x-24 ">
                <div className=" w-[185px]">
                  <div className=" box-content w-full  h-[265px] items-center justify-center flex border-solid border border-grey-2 ">
                    <ImageWithFallback
                      src={materialImageURL}
                      //src="https://wallpapers.com/images/high/homer-simpson-portrait-650c9n5a4fpapnzc.png"
                      fallbackSrc={fallbackImage}
                      alt={material.imageURL}
                      className={`w-full h-auto`}
                    />
                  </div>
                  <div className=" mt-[10px] mb-[9px]">
                    <p className=" text-[16px] leading-5 font-semibold line-clamp-2 mb-1">
                      {material.productName}
                      {/* Some File.pdf */}
                    </p>

                    {material.sizeMB > 0 && (
                      <p className=" text-[14px] leading-4 text-grey-5 font-inter font-normal	 ">{formatBytes(material.sizeMB)}</p>
                    )}


                  </div>
                </div>
                <div className=" flex flex-col align-middle  items-center w-80 ">
                  <p className="text-center pb-6 font-semibold font-inter">
                    {material.isDirectUrl ? (<T keyName="qr-modal-text-view" />) : (<T keyName="qr-modal-text-download" />)}
                  </p>
                  <QRCode value={materialDownloadURL} size={128} style={{ height: "75%", width: "75%" }} viewBox={`0 0 256 256`} bgColor={"white"} />
                </div>
              </div>
            </div>
          </div>
        }

        {/* desktop preview modal */}
        {showPreviewModal && (
          <div className="z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center" onClick={() => setShowPreviewModal(false)}>
            <div className="bg-white rounded-lg p-4" onClick={(e) => e.stopPropagation()}>
              <div className="flex justify-end">
                <svg
                  className="cursor-pointer"
                  onClick={() => setShowPreviewModal(false)}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="flex flex-row justify-center p-8 gap-x-8">
                <ImageWithFallback
                  src={materialImageURL}
                  // src="https://wallpapers.com/images/high/homer-simpson-portrait-650c9n5a4fpapnzc.png"
                  fallbackSrc={fallbackImage}
                  alt={material.imageURL}
                  className={`h-auto transition-opacity duration-300`}
                  style={{ maxHeight: "80vh", maxWidth: "100%", justifyContent: "center" }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const ImageWithFallback = ({ src, fallbackSrc, alt, className, style }) => {
  const [imageSrc, setImageSrc] = useState(src);

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const handleImageError = () => {
    setImageSrc(fallbackSrc);
  };

  return <img src={imageSrc} onError={handleImageError} alt={alt} className={className} style={style} />;
};

export default ResultsCard;